
<script setup>
    import { ref, watch, computed, onMounted } from "vue";
    import { hapticsImpact } from "mobile.utils.ts";

    import MSearch from "mobile.vue.components.Search.vue";
    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";

    const props = defineProps(["filter"]);

    const data = ref([]);

    const search = ref("");

    const loading = ref(true);

    async function update() {
        data.value = [];
        loading.value = true;

        const options = {};

        //options.whereClause = props.filter.dataObject.recordSource.whereClause;
        options.whereClause = props.filter.dataObject.recordSource.combinedWhereClause;
        options.filterString = props.filter.getFilterString(props.filter.field);

        if (search.value) {
            const searchClause = `[${props.filter.field.field}] LIKE '%${search.value}%'`;
            if (options.filterString) {
                options.filterString = `(${options.filterString}) AND (${searchClause})`;
            } else {
                options.filterString = searchClause;
            }
        }

        console.log(options.filterString);

        options.viewName = props.filter.dataObject.viewName;
        options.maxRecords = 50;
        options.fields = [
            {name: props.filter.field.field, alias: "value", groupByOrder: 1},
            {name: props.filter.field.field, alias: "count", aggregate: "COUNT", sortOrder: 1, sortDirection: "desc"},
        ];

        let rows = await props.filter.dataObject.dataHandler.distinct(options);

        console.log("rows", rows);

        rows = rows.filter(e => e.value != null);

        const included = rows.map(e => e.value);

        console.log("included", included);

        // add included rows
        for (let name of props.filter.field.storage.values) {
            if (!included.includes(name)) {
                rows.push({ value: name, count: 0 });
                console.log("adding fake row");
            }
        }

        for (let row of rows) {
            row.selected = props.filter.field.storage.values.includes(row.value);
            row._selected = row.selected;
        }

        data.value = rows;

        loading.value = false;
    }

    onMounted(async () => {
        update();
    });

    watch(search, () => {
        update();
    });

    function onClick(row) {
        row.selected = !row.selected;
        /*
        if (field.storage.values.includes(row.value)) {
            field.storage.values = field.storage.values.filter(e => e !== row.value);
        } else {
            field.storage.values.push(row.value);
        }
        */
        props.filter.field.setTempValue(data.value.filter(e => e.selected).map(e => e.value));
        hapticsImpact("SelectionClick");
    }

    function isChanged() {
        return data.value.filter(e => e.selected !== e._selected).length > 0;
    }
    
    function apply() {
        props.filter.selectField(null);
        hapticsImpact("MediumImpact");
    }

    watch(() => props.filter.field, () => {
    /*
        if (!props.filter.field && !loading.value) {
            console.log("APPLY");
            props.filter.field.setValues(data.value.filter(e => e.selected).map(e => e.value));
            props.filter.updateFilterString();
        }
        */
        if (props.filter.field) {
            console.log("props.filter.field changed");
            update();
        }
    });
</script>

<template>
    <m-container :showSpinner="loading">
        <div class="mt-3 px-3">
            <m-search v-model="search" @search="update" />
        </div>
        <m-content>
            <div class="mt-2 px-3 pb-3">
                <template v-if="data.length">
                    <template v-for="(row, index) in data">
                        <a class="d-flex align-items-center list-item" :class="{ 'border-top': index > 0 }" style="padding: 0.75rem 1.00rem; gap: 0.5rem; color: black; font-size: 0.9em; text-decoration: none;" @click="onClick(row)">
                            <div>
                                <template v-if="row.selected">
                                    <i class="fas fa-check-square text-primary" style="font-size: 1.5em;" />
                                </template>
                                <template v-else>
                                    <i class="fal fa-square text-muted" style="font-size: 1.5em;" />
                                </template>
                            </div>
                            <div class="text-muted">
                                ({{ row.count }})
                            </div>
                            <div>
                                {{ row.value }}
                            </div>
                        </a>
                    </template>
                </template>
                <template v-else-if="!loading">
                    <div class="p-3 d-flex justify-content-center text-muted">
                        {{ $t("No results found") }}
                    </div>
                </template>
            </div>
        </m-content>
        <transition name="raise">
            <template v-if="isChanged()">
                <div class="p-3 d-flex">
                    <button class="flex-grow-1 btn btn-primary" @click="apply">
                        {{ $t("Apply") }}
                    </button>
                </div>
            </template>
        </transition>
        <!--
        <div class="p-3 d-flex apply-button" :class="{ 'show': isChanged() }">
            <button class="flex-grow-1 btn btn-primary" @click="apply">
                {{ $t("Apply") }}
            </button>
        </div>
        -->
    </m-container>
</template>

<style scoped>
    .list-item {
        background-color: white;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: rgb(95%, 95%, 95%);
        }
    }

    .raise-enter-from {
        transform: translate(0, 100%);
        opacity: 0%;
    }
    .raise-enter-active {
        transition: all 200ms ease-in-out;
    }
    .raise-enter-to {
    }
    .raise-leave-from {
    }
    .raise-leave-active {
        transition: all 400ms ease-in-out;
    }
    .raise-leave-to {
        transform: translate(0, 100%);
        opacity: 0%;
    }
</style>
